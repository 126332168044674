body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: radial-gradient(
    circle at top,
    #fbd27d,
    #ffaf00,
    #ff6345,
    #ff6345
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTab-root {
  min-width: inherit !important;
}

.MuiPaper-root {
  /* background-color: #fff6e1 !important; */
  /* background-color: #00000098 !important; */
}

.MuiButton-containedPrimary {
  color: #fff !important;
}

.MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.MuiBox-root {
  padding: 0 !important;
}
.MuiListItem-secondaryAction {
  padding-right: 120px !important;
}
